<template>
  <div class="space-y-4">
    <CCard spacing>
      <CTypo tstyle="title3" class="mb-3">
        {{ $t('settings') }}
      </CTypo>
      <CButton
        variant="primary"
        :loading="loading"
        @click="redirectToUpdateLink"
      >
        {{ $t('merchant.updateCompanyData') }}
      </CButton>
    </CCard>
    <CCard spacing>
      <CTypo tstyle="title3" class="mb-3">
        {{ $t('merchant.updateAddress') }}
      </CTypo>
      <CForm 
        class="mt-4" 
        @submit="onAddressUpdate" 
        :error-fields-response="addressValidationErrors"
        :loading="addressLoading"
      >
        <div class="grid grid-cols-12 gap-x-4">
          <div class="col-span-8 md:col-span-9">
            <CTextField
              v-model="form.street"
              :label="$t('address.street')"
              required
            />
          </div>
          <div class="col-span-4 md:col-span-3">
            <CTextField
              v-model="form.number"
              :label="$t('address.number')"
              required
            />
          </div>
          <div class="col-span-12">
            <CTextField
              :label="$t('address.postalCode')"
              v-model="postalCodeSearchString"
              :data="currentPostalCodeResults"
              :debounce-typing="150"
              :clear-on-select="false"
              field="postalCode"
              required
              autocomplete-component
              @typing="searchPostalCode"
              @select="postalCodeSelect"
            >
              <template v-slot="props">
                {{ props.option.postalCode }} {{ props.option.city }}
              </template>
            </CTextField>
          </div>
          <div class="col-span-12">
            <CTextField
              :label="$t('address.city')"
              :value="currentCity"
              disabled
            />
          </div>
        </div>
      </CForm>
    </CCard>
    <CCard spacing>
      <CTypo tstyle="title3" class="mb-3">
        {{ $t('merchant.updateSupportPhone') }}
      </CTypo>
      <CForm 
        class="mt-4" 
        @submit="onPhoneUpdate" 
        :error-fields-response="phoneValidationErrors"
        :loading="phoneLoading"
      >
        <CTextField
          v-model="phone"
          :placeholder="$t('phone')"
          type="tel"
          autocomplete="tel"
          required
        />
      </CForm>
    </CCard>
    <CCard spacing>
      <CTypo tstyle="title3" class="mb-3">
        {{ $t('merchant.updateInvoiceEmail') }}
      </CTypo>
      <CForm 
        class="mt-4" 
        @submit="onInvoiceEmailUpdate" 
        :error-fields-response="invoiceEmailValidationErrors"
        :loading="invoiceEmailLoading"
      >
        <CTextField
          v-model="invoiceEmail"
          type="email"
          :placeholder="$t('email')"
        />
      </CForm>
    </CCard>
    <CCard spacing>
      <CTypo tstyle="title3" class="mb-3">
        {{ $t('merchant.updateNotificationEmail') }}
      </CTypo>
      <CForm 
        class="mt-4" 
        @submit="onNotificationEmailUpdate" 
        :error-fields-response="notifiationEmailValidationErrors"
        :loading="notificationEmailLoading"
      >
        <CTextField
          v-model="notificationEmail"
          type="email"
          :placeholder="$t('email')"
        />
      </CForm>
    </CCard>
  </div>
</template>


<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import {
  GET_MERCHANT,
  GET_STRIPE_ONBOARDING_LINK,
  SEARCH_POSTAL_CODES,
  UPDATE_MERCHANT_ADDRESS,
  UPDATE_MERCHANT_EMAILS,
  UPDATE_MERCHANT_PHONE,
} from '@/store/actionTypes';
import { CURRENT_POSTAL_CODE_RESULTS, THIS_MERCHANT } from '@/store/gettersTypes';
import { RESET_POSTAL_CODE_SEARCH, SET_SUCCESS_NOTIFICATION } from '@/store/mutationsTypes';

export default {
  data: () => ({
    loading: false,
    form: {
      id: null,
      street: null,
      number: null,
      postalCodeId: null,
    },
    invoiceEmail: null,
    notificationEmail: null,
    postalCodeSearchString: null,
    selectedPostalCode: {},
    phone: null,
    addressValidationErrors: null,
    invoiceEmailValidationErrors: null,
    notifiationEmailValidationErrors: null,
    phoneValidationErrors: null,
    addressLoading: false,
    invoiceEmailLoading: false,
    notificationEmailLoading: false,
    phoneLoading: false,
  }),

  computed: {
    ...mapGetters([THIS_MERCHANT, CURRENT_POSTAL_CODE_RESULTS]),
    updateLoading() {
      return this.$store.state.merchant.updateLoading;
    },
    currentCity() {
      return this.selectedPostalCode?.city || null;
    },
  },

  watch: {
    thisMerchant: {
      immediate: true,
      handler() {
        this.form.id = this.thisMerchant.address.id;
        this.form.street = this.thisMerchant.address.street;
        this.form.number = this.thisMerchant.address.number;
        this.form.postalCodeId = this.thisMerchant.address.postalCodeId;
        this.postalCodeSelect({
          id: this.form.postalCodeId,
          city: this.thisMerchant.address.location.city,
        });
        this.postalCodeSearchString = this.thisMerchant.address.location.postalCode;
        this.invoiceEmail = this.thisMerchant.invoiceEmail;
        this.notificationEmail = this.thisMerchant.notificationEmail;
        this.phone = this.thisMerchant.phone;
      },
    },
    form: {
      handler() {
        this.addressValidationErrors = null;
      },
      deep: true,
    },
    invoiceEmail() {
      this.invoiceEmailValidationErrors = null;
    },
    notificationEmail() {
      this.notifiationEmailValidationErrors = null;
    },
    phone() {
      this.phoneValidationErrors = null;
    },
  },

  mounted() {
    this.$nextTick(() => {
      const query = this.$route.query.updatelink;
      if (query === 'refresh') {
        this.redirectToUpdateLink();
      } else if (query === 'return') {
        this.getMerchant(this.thisMerchant.id);
      }
    });
  },

  methods: {
    ...mapActions([
      GET_STRIPE_ONBOARDING_LINK,
      GET_MERCHANT,
      UPDATE_MERCHANT_ADDRESS,
      SEARCH_POSTAL_CODES,
      UPDATE_MERCHANT_EMAILS,
      UPDATE_MERCHANT_PHONE,
    ]),
    ...mapMutations([RESET_POSTAL_CODE_SEARCH, SET_SUCCESS_NOTIFICATION]),
    async redirectToUpdateLink() {
      this.loading = true;
      const link = await this.getStripeOnboardingLink({
        merchantId: this.thisMerchant.id,
        type: 'account_update',
        refreshUrl: `${window.location.href}?updatelink=refresh`,
        returnUrl: `${window.location.href}?updatelink=return`,
      });
      window.location.href = link.url;
    },
    async onAddressUpdate() {
      const { street, number, postalCodeId } = this.form;
      this.addressLoading = true;
      try {
        await this.updateMerchantAddress({
          street,
          number,
          postalCodeId,
        });
        this.setSuccessNotification({ content: null, show: true });
      } catch (e) {
        if (e.response.data.code === 'E_VALIDATION_FAILURE') {
          this.addressValidationErrors = e.response;
        } else {
          throw e;
        }
      } finally {
        this.addressLoading = false;
      }
    },
    searchPostalCode(value) {
      const currentCountry = 'DE';
      if (value.length >= 3) {
        this.searchPostalCodes(`${currentCountry}-${value}`).then(() => {
          if (value.length === 5 && this.currentPostalCodeResults.length > 0) {
            this.postalCodeSelect(this.currentPostalCodeResults[0]);
          } else if (value.length === 5) {
            this.postalCodeSelect(null);
          }
        });
      } else {
        this.resetPostalCodeSearch();
      }
    },
    postalCodeSelect(data) {
      this.selectedPostalCode = data;
      if (data) {
        this.form.postalCodeId = data.id;
      }
    },
    async onInvoiceEmailUpdate() {
      const { invoiceEmail } = this;
      this.invoiceEmailLoading = true;
      try {
        await this.updateMerchantEmails({ invoiceEmail });
        this.setSuccessNotification({ content: null, show: true });
      } catch (e) {
        if (e.response.data.code === 'E_VALIDATION_FAILURE') {
          this.invoiceEmailValidationErrors = e.response;
        } else {
          throw e;
        }
      } finally {
        this.invoiceEmailLoading = false;
      }
    },
    async onNotificationEmailUpdate() {
      const { notificationEmail } = this;
      this.notificationEmailLoading = true;
      try {
        await this.updateMerchantEmails({ notificationEmail });
        this.setSuccessNotification({ content: null, show: true });
      } catch (e) {
        if (e.response.data.code === 'E_VALIDATION_FAILURE') {
          this.notifiationEmailValidationErrors = e.response;
        } else {
          throw e;
        }
      } finally {
        this.notificationEmailLoading = false;
      }
    },
    async onPhoneUpdate() {
      const { phone } = this;
      this.phoneLoading = true;
      try {
        await this.updateMerchantPhone({ phone });
        this.setSuccessNotification({ content: null, show: true });
      } catch (e) {
        if (e.response.data.code === 'E_VALIDATION_FAILURE') {
          this.phoneValidationErrors = e.response;
        } else {
          throw e;
        }
      } finally {
        this.phoneLoading = false;
      }
    },
  },
};
</script>
